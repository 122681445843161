/* eslint-disable no-unused-vars */
import React from "react";
import { Container } from "react-bootstrap";
import { Row, Toast, Col, Button } from "react-bootstrap";
import Config from "./Plugins/Config.json";
import Form from "react-bootstrap/Form";
import axios from "axios";
const Login = () => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [toast, showToast] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState("");
    const status = sessionStorage.getItem("status");
    React.useEffect(() => {
        if (status === "invalid") {
            setToastMessage("Session expired, please login again");
            showToast(true);
            sessionStorage.removeItem("status");
        }
    }, []);
    const Signin = async () => {
        if (username === "") {
            setToastMessage("Please fill username");
            showToast(true);
            return;
        }
        if (password === "") {
            setToastMessage("Please fill a password");
            showToast(true);
            return;
        }
        const { data } = await axios({
            method: "POST",
            url: `${Config.url}/api/login`,
            headers: {
                Authorization: "Basic " + btoa(`${username}:${password}`), //localStorage.getItem("token"),
            },
        });
        if (data.token === null) {
            setToastMessage("Username & Password are invalid");
            showToast(true);
            return;
        } else {
            localStorage.setItem("token", data.token);
            sessionStorage.setItem("status", "logged");
            window.location.href = "/";
        }
    };
    return (
        <Container>
            <Row>
                <Col xs={6}>
                    <Toast onClose={() => showToast(false)} show={toast} delay={3000} autohide style={{ position: "absolute", top: 50, right: 20 }}>
                        <Toast.Header>
                            {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-exclamation" viewBox="0 0 16 16">
                                <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.553.553 0 0 1-1.1 0L7.1 4.995z" />
                            </svg>
                            <strong className="me-auto">Warning</strong>
                            <small>right now</small>
                        </Toast.Header>
                        <Toast.Body value={toastMessage}>{toastMessage}</Toast.Body>
                    </Toast>
                </Col>
            </Row>
            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="login shadow-lg">
                    <center>
                        <h1 className="mb-4">Login</h1>
                    </center>

                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="username" placeholder="Enter username" onChange={(e) => setUsername(e.target.value)} value={username} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword" onChange={(e) => setPassword(e.target.value)} value={password}>
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Enter Password" />
                        </Form.Group>
                        <center>
                            <Button variant="primary" onClick={() => Signin()}>
                                Login
                            </Button>
                        </center>
                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default Login;
