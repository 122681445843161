import React from "react";
import { Button, Container, Row, Col, Toast } from "react-bootstrap";
import Verify from "./Plugins/Verify";
import socket from "./Socket";
import { socket_connected, sendMessage } from "./Socket";

const Messages = () => {
    const [textArea, setTextArea] = React.useState("");
    const [sender, setSender] = React.useState("");
    const [toast, showToast] = React.useState(false);
    const [toastMessage, setToastMessage] = React.useState("");
    React.useEffect(() => {
        Verify();
    }, []);
    const handleSender = (e) => {
        setSender(e.target.value);
    };
    const handleTextArea = (event) => {
        setTextArea(event.target.value);
    };
    const button_send = () => {
        const defaultJid = "@s.whatsapp.net";
        if (sender === "") {
            setToastMessage("Please provide a number");
            showToast(true);
        } else if (textArea === "") {
            setToastMessage("Please type your message!");
            showToast(true);
        }
        if (sender.startsWith("08")) {
            const serializedJid = "62" + sender.substring(1) + defaultJid;
            sendMessage(serializedJid, textArea);
        } else if (sender.startsWith("+62")) {
            const serializedJid = sender.substring(1) + defaultJid;
            sendMessage(serializedJid, textArea);
        } else {
            setToastMessage("Please provide a valid number");
            showToast(true);
        }
    };
    socket.on("notification", (msg, success) => {
        setToastMessage(`${msg}`);
        showToast(true);
        if (success) {
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }
    });
    return (
        <div>
            <Row>
                <Col xs={6}>
                    <Toast onClose={() => showToast(false)} show={toast} delay={3000} autohide style={{ position: "absolute", top: 100, right: 20 }}>
                        <Toast.Header>
                            {/* <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" /> */}
                            <strong className="me-auto">Notification</strong>
                            <small>right now</small>
                        </Toast.Header>
                        <Toast.Body value={toastMessage}>{toastMessage}</Toast.Body>
                    </Toast>
                </Col>
            </Row>
            <Container>
                <div className="chatbox text-bg-primary">
                    <div className="input-group mb-3">
                        <div className="input-group mb-3">
                            <span className="sendto input-group-text" id="inputGroup-sizing-default">
                                Send to
                            </span>
                            <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" value={sender} onChange={handleSender} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <textarea className="form-control" placeholder="Type a Message" style={{ height: "300px", width: "550px" }} value={textArea} onChange={handleTextArea}></textarea>
                    </div>
                    {socket_connected ? (
                        <div className="d-flex justify-content-center mt-3">
                            <Button variant="success" onClick={() => button_send()}>
                                Send Message
                            </Button>
                        </div>
                    ) : null}
                </div>
            </Container>
        </div>
    );
};

export default Messages;
