import QRCode from "qrcode";
import React, { useState } from "react";
import socket from "./Socket";
import Verify from "./Plugins/Verify";
const Scan = () => {
    Verify();
    const [QR, setQR] = useState(null);
    const [status, changeStatus] = useState("Getting Status...");
    const [hide, show] = useState(false);
    const [button, showButton] = useState(false);

    socket.on("connect", () => socket.emit("minta-qr"));
    socket.on("kasih-qr", (data) => {
        QRCode.toDataURL(data, (error, url) => {
            if (error) console.log(error);
            setQR(url);
            changeStatus("Scan Bang!");
            show(true);
        });
    });
    socket.on("connect_error", () => {
        show(false);
        changeStatus("Server Disconnected...");
        socket.close();
        showButton(true);
    });
    socket.on("restart", () => {
        show(false);
        changeStatus("Getting Status...");
    });
    socket.on("connected", () => {
        show(false);
        changeStatus("WhatsApp is Connected!");
    });
    const refresh = () => {
        socket.connect();
        showButton(false);
        changeStatus("Connecting...");
        socket.on("connect", () => {
            socket.emit("minta-qr");
        });
    };
    return (
        <div>
            <div className="d-flex justify-content-center mt-5">
                <h1 className="text-dark">Hi, Setsu</h1>
            </div>
            {hide ? (
                <div className="d-flex justify-content-center m-5">
                    <img src={QR} alt="Scan QR bang!" className="qrcode bg-dark shadow" width="400px" height="400px" />
                </div>
            ) : null}
            <div className="status d-flex justify-content-center p-5">
                <h1>{status}</h1>
            </div>
            <div className="buttons">
                {button ? (
                    <center>
                        <button type="button" className="btn btn-outline-primary" onClick={() => refresh()}>
                            Refresh
                        </button>
                    </center>
                ) : null}
            </div>
        </div>
    );
};

export default Scan;
