import axios from "axios";
import Config from "./Config.json";
const Verify = () => {
    const token = localStorage.getItem("token");
    if (token === null) {
        window.location.href = "/login";
        return;
    } else if (token) {
        axios.post(`${Config.url}/api/verify`, {}, { headers: { Authorization: "Bearer " + token } }).then((res) => {
            if (res.data.matched === false) {
                localStorage.removeItem("token");
                window.location.href = "/login";
                sessionStorage.setItem("status", "invalid");
                return;
            }
        });
    }
};
export default Verify;
