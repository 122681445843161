import io from "socket.io-client";
const PORT = 11013;
const url = "http://node4434-setsu.jh-beon.cloud";
const socket = io(`${url}:${PORT}`);
let socket_connected;
let wa_status;
socket.on("connect", () => {
    socket_connected = true;
});

socket.on("connect_error", () => {
    socket.close();
    socket_connected = false;
});
const sendMessage = (jid, message) => {
    socket.emit("send-message", jid, message);
};

export default socket;
export { socket_connected, wa_status, sendMessage };
