import Header from "./Components/Header";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Scan from "./Components/Scan";
import Messages from "./Components/Messages";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";
import Login from "./Components/Login";
function App() {
    const localTokenUser = localStorage.getItem("token");
    return (
        <Router>
            {localTokenUser ? <Header /> : null}
            <Routes>
                <Route exact path="/" element={<Scan />} />
                <Route path="/message" element={<Messages />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </Router>
    );
}

// const Redirect = ({ label, to, activeOnlyExact }) => {
//     let match = useRouteMatch({
//         path: to,
//         exact: activeOnlyExact,
//     });
// };
export default App;
