import { Navbar, Nav, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
const Header = () => {
    const Logout = () => {
        localStorage.removeItem("token");
        window.location.href = "/login";
        sessionStorage.setItem("status", "logout");
    };
    return (
        <Navbar bg="white" expand="lg" className="shadow">
            <Container>
                <Navbar.Brand href="/" style={{ marginTop: "8px" }}>
                    <img src="logo.svg" width="40px" className="me-2" alt="Home"></img>Setsu Bot
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mt-1">
                        <Nav.Link href="/">Scan QR</Nav.Link>
                        <Nav.Link as={Link} to="/message">
                            Messages
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Button variant="danger" onClick={() => Logout()}>
                    Logout
                </Button>
            </Container>
        </Navbar>
    );
};

export default Header;
